header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
main {
	/* margin-top: 17vh; */
	min-height: 92vh;
	text-align: center;
	overflow-y: hidden;
}

.fondoProfile {
	background-image: url("../../assets/fondos/FONDO1.png");
}

.containerProfile {
margin-top: 0;
	min-height: 92vh;
	text-align: center;
	overflow-y: hidden;
	/* padding-top: 12vh !important; */
	/* padding-bottom: 3vh !important; */
	/* min-height: 92vh; */
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}
.containerInfoProfile {
	display: flex;
	background-color: rgba(255, 255, 255, 0.5);
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	/* max-width: 25vw; */
	padding: 10px 25px !important;
	border-radius: 10px;
	/* position: relative; */
}
.textProfile {
	width: 100%;
	text-align: left;
	font-size: 1.2rem;
	font-weight: bold;
	font-family: Montserrat;
}

.liProfile {
	font-family: Montserrat;
}
.textProfileHistorial {
	width: 100%;
	text-align: center;
	font-size: 1.2rem;
}
.infoProfile {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	transition: all ease-in 1s;
}
.infoHistorial {
	/* display: none; */
	background-image: url("../../assets/images/TOAST-FONDO.webp");
	max-height: 30vh;
	overflow: scroll;
	overflow-x: hidden;
	border-radius: 10px;
	box-shadow: 8px 9px 5px 0px rgba(0, 0, 0, 0.18);
	-webkit-box-shadow: 8px 9px 5px 0px rgba(0, 0, 0, 0.18);
	-moz-box-shadow: 8px 9px 5px 0px rgba(0, 0, 0, 0.18);
	-webkit-animation: slide-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.tituloHistorialContainer {
	text-align: center;
	display: flex;
	justify-content: center;

	position: sticky;
	top: 0;
	background-color: var(--color-rosa-claro); /* Cambia el color de fondo según tus preferencias */
	z-index: 1;
	padding: 10px; /* Ajusta el padding según tus preferencias */
}

.tituloHistorial {
	margin: 0;
	font-size: 1.25rem; /* Ajusta el tamaño de fuente según tus preferencias */
	font-weight: bold;
}

.containerHistorial {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.containerHistorial:hover {
	cursor: pointer;
	background-color: var(--color-rosa-claro);
}

.categorySection {
	height: 50vh;
	overflow: scroll;
	overflow-x: hidden;
}

.diamanteHistorial {
	color: var(--color-verde);
}

@-webkit-keyframes slide-left {
	0% {
		-webkit-transform: translateX(100px);
		transform: translateX(100px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-left {
	0% {
		-webkit-transform: translateX(100px);
		transform: translateX(100px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
.botonEliminarCuenta > button {
	width: 80%;
	background-color: var(--color-verde);
	border-radius: 15px;
	font-weight: bold;
	font-size: 1rem;
	letter-spacing: 2px;
}
@media (max-width: 550px) {
	.containerProfile {
		margin-top: 0;
	}
	.containerInfoProfile {
		flex-direction: column;
	}
}
